<template>
  <section>
    <div class="bg-white">
    <div class="container">
      <div ref="rootUl" id="root-ul" data-widgetId="610787afda749d7d74aafae6" data-city="London" data-primaryColor="#226cf5" data-fontColor="#333" data-iconColor="#226cf5"></div>
    </div>
  </div>
  </section>
</template>

<script>
export default {
  mounted() {
    const widgetId = this.$refs.rootUl.getAttribute('data-widgetId');
    const script = document.createElement('script');
    script.src = 'https://d3hk5c2fo9op52.cloudfront.net/widget.js';
    script.onload = () => {
      // Widget script loaded
    };
    document.body.appendChild(script);
  }
}
</script>

<style>
.topBar {
  top: 80px !important;
}
#root-ul .fillingFast{
  background-color: var(--primary);
}
#root-ul .offer_section{
  background-color: var(--primary);

}
</style>
